<template>
  <div>
    <vs-row class="heading-row">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <h1 class="heading">تقرير المنتجات</h1>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-w="3">
        <p>من التاريخ</p>
        <p>الى التاريخ</p>
      </vs-col>
      <vs-col vs-w="3">
        <h6>{{ from_date }}</h6>
        <h6>{{ to_date }}</h6>
      </vs-col>
    </vs-row>
    <vs-row class="order-items justify-content-end">
      <vs-col vs-align="center" vs-w="12">
        <vs-table hoverFlat :data="data" class="w-full order-table">
          <template slot="thead">
            <vs-th>رقم المنتج</vs-th>
            <vs-th>اسم المنتج</vs-th>
            <vs-th>السعر</vs-th>
            <vs-th>الكمية المباعة</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr v-for="(tr, index) in data" :key="index">
              <vs-td> {{ tr.id }} </vs-td>
              <vs-td>{{ tr.title }}</vs-td>
              <vs-td> {{ tr.price }}</vs-td>
              <vs-td>{{ tr['Sold quantity'] }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      required: true,
      type: Array,
    },
    from_date: {
      required: true,
    },
    to_date: {
      required: true,
    },
  },
};
</script>


<style lang="scss" scoped>
@import "@/assets/scss/invoice.scss";
</style>
<style lang="scss">
@import "@/assets/scss/print.scss";
</style>