<template>
  <div id="invoice-page">
    <vx-card title="تقرير  المنتجات" title-color="primary">
      <vs-divider position="left" textClass="text-size" color="primary">
        متغيرات التقرير
      </vs-divider>
      <div class="vx-row">
        <div class="vx-col w-1/4">
          <label class="vs-input--label"> رقم المنتج</label>
          <v-select
            multiple
            name="products"
            class="w-full"
            label="sku"
            :options="products"
            v-model="product_ids"
            :reduce="(item) => item.id"
            :filterable="true"
          ></v-select>
        </div>
        <div class="vx-col w-1/4">
          <vs-input
            type="date"
            class="w-full"
            name="expire"
            v-model="from_date"
            v-validate="'required'"
            label="مـــن"
          />
        </div>
        <div class="vx-col w-1/4">
          <vs-input
            type="date"
            class="w-full"
            name="expire"
            v-model="to_date"
            v-validate="'required'"
            label="الى"
          />
        </div>
        <div class="vx-col w-1/4">
          <vs-button
            class="mb-base mr-3 mt-5"
            icon-pack="feather"
            @click="getReport"
            >إستخراج تقرير
          </vs-button>
        </div>
      </div>
    </vx-card>
    <div class="flex items-center justify-end mt-4" v-if="report_data.length">
      <vs-button
        class="mb-base mr-3"
        icon-pack="feather"
        icon="icon icon-file"
        @click="printInvoice"
        >طباعة</vs-button
      >
    </div>
    <vx-card v-if="report_data.length" class="mt-4" id="invoice-container">
      <product-report
        :data="report_data"
        :from_date="from_date"
        :to_date="to_date"
      ></product-report>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import productReport from "./components/products.vue";
export default {
  components: {
    vSelect,
    productReport,
  },
  data() {
    return {
      product_ids: null,
      to_date: "",
      from_date: "",
      report_data: [],
    };
  },
  computed: {
    products() {
      return this.$store.state.products.products_sku;
    },
  },
  methods: {
    ...mapActions("products", ["productsSKU"]),
    ...mapActions("reports", ["fetchProductReport"]),
    printInvoice() {
      window.print();
    },
    getReport() {
      let bodyFormdata = new FormData();
      for (let i = 0; i < this.product_ids.length; i++)
        bodyFormdata.set(`products[${i}]`, this.product_ids[i]);
      bodyFormdata.set("from_date", this.from_date);
      bodyFormdata.set("to_date", this.to_date);
      this.fetchProductReport(bodyFormdata).then((response) => {
        this.report_data = response.data.data;
        if (this.report_data.length) {
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم إستخراج التقرير بنجاح",
            color: "success",
          });
        } else {
          this.$vs.notify({
            title: "عملية غير ناجحة",
            text: "إما إنه لا يوجد بيانات لهذا العميل أو إنه لا توجد بيانات للفترة الزمنية التي تم تحديدها.",
            color: "warning",
          });
        }
      });
    },
  },
  created() {
    this.productsSKU();
  },
  mounted() {
    this.$emit("setAppClasses", "invoice-page");
  },
};
</script>

<style>
</style>